body{
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

body {
    padding-top: 50px;
	@media print {
		padding-top: 0px;
	}
}

.normalText{
	font-size: $normalFontSize;
	color: $darkText;
	font-weight: normal;
}

.container-fluid{
	width:95%;
}
.container-fluid:not(.footer){
	min-height: calc(100vh - 250px);
}

.contentWrap {
	padding: 40px 0px;
    
}

.required{
	color:red;
}

.anonymousOrNot button{
	margin: 0 10px;
}

.fieldset textarea,.fieldset input[type=file]{
	/*width:50%;*/
	height:40px;
	display: inline-block;
}
.fieldset label{
	font-weight: normal;

	vertical-align: top;
}
.fieldset{
	margin-top: 40px;
}

#selectCompany{
	margin:10px 0;
}

.fileUpload{
	margin: 20px auto;
}

#contactInfo{
	margin:0 0 20px 0;
	display:none;
}

#contactInfo label{
	font-weight: normal;
	width:60px;
}

.form-horizontal{
	margin: 40px 0 0 0;
}

.logo{
	width: 55px; 
	/*position: absolute;*/
}

.logoAbc{
	width: 235px; 
	/*position: absolute;*/
}

.logoAbcEn{
	width: 295px; 
	/*position: absolute;*/
}

body>.container{
	background-color: white;
	height: 100%;
	width: 1200px;
	flex:1;
}

.navbar{
	width: 95%;
	margin: 0 auto;
	/*font-family: verdana, sans-serif;*/
	font-size: 22px;
	color: rgb(136, 136, 136);
	background-image:inherit;
	background-color:white;
	border:0;
	min-height: 80px;
}
.navbar-header{
	width:100%;
	display: flex;
}

.navLinks{
	font-size: 14px;
	display: inline-block;
	/*padding-top:35px;*/
	padding-left:50px;
	/*width:350px;*/

	a{
		margin-right: 10px ;
	}
}

.navbar-nav li{
/*	height:80px;
	padding-top:20px;*/
	
}

.navLeft{
	margin-left: 1%;
}

.navRight{


	margin-top: 33px;
    text-align: left;
	width: 100%;
	font-size: 14px; 
	padding-right: 1%;

	a{
		/* margin: 47px 0 0 0; */
		/*display: block;*/
		/*clear: both;*/
	}


}

.loginLinks{
	width:200px;
	float:right;
	text-align: right;
}

.navbar-nav li:hover{
	background-color: rgb(52, 104, 192);
	transition: background-color 0.5s ease;
}
.navbar-default .navbar-nav > li > a{
	color: rgb(136, 136, 136);
	line-height: 100px;
	height: 80px;
	padding:0 15px;
	text-transform: lowercase;
}
.navbar-default .navbar-nav > li > a:hover{
	color: white;
	transition: color 0.5s ease;
}

.entry-title{
	font-size: 16px;
    color: #555555;
    line-height: 130%;
}


.colCentered{
	float: none;
	margin: 0 auto;
}

/* index page */
.userChoice{
	padding: 0;
	/*margin:10px 0 0 0;*/
	p{
		text-align: justify;
		margin: 20px 0 0 0;
	}	
	.col-sm-6{
		text-align: center;
	}
	.welcome{
		margin-bottom:2em;
		margin-top:3em; 
	}
}

.otherChannels{
	margin: 50px 0 0 0;
}
.otherChannels{
	
	margin: 50px 0 0 0;

	strong{
		font-weight: normal;
	}

	ul{
		width:320px;
		margin:10px auto 0 auto;
		text-align: left;
	}

	.glyphicon{
		font-size: 25px;
		margin-right: 10px;
	}

}


#enterFraudType{
	select {
		width: 300px;
		display: inline-block;
	}
}


/* thankyou */ 
.thankYou{

	text-align: center;

	.infoThankYou{
		display:inline-block;
		margin:40px 0;
		float:none;
	}
}

/* footer */
.footer{
	padding-top: 40px;
	.footerCustom a{
		color:$darkText;
	}
	.footerCustom{
		min-height: 5em;
	}
}

.messageText{
	border-bottom:1px solid $softBorder;
	padding:3px 0;
}


.dateSent{
	color:$lightText;
    /*font-size: 11px;*/
    /*position: relative;*/
    /*top: 1em;	    */
}

.lightText{
	color:$lightText;
}
.darkText{
	color:$darkText;
}

.smallTitle{
	margin: 30px 0 20px 0;
}


.loginForm{
	/*width:500px;*/
    margin: 0 auto;
}


.explanation{
	color:#555;
	font-size: 0.9em;
}

.footer{
	.trademark{
		float:right; 
		color:gray;
		/*display:none;*/
		/*margin-top:3em;*/
	}
}

body[data-page=FrontEndController\@index]{
	.trademark{
		display:block;
	}
}

.ucfirst::first-letter{
	text-transform: capitalize;	
}

.tooltip-inner{
    /*background-color: $lightText; */
	opacity: 0.8;
    max-width: 24em;
}


.fileToUpload .fa{
	margin-right: 0.5em;
}

.btn{
	border-radius: 14px !important;
}

.expand{
	color:$darkText;
	&:hover{
		text-decoration:underline;
	}
}

.collapsing.noAnimation {
    -webkit-transition: none;
    transition: none;
    display: none;
}

body[data-route=frontpage].loggedInUser .flags.hide{
	display:none !important;
}

.btn:focus{
	outline: none;
}

@media print {
  a[href]:after {
    content: none !important;
  }
}



/* GRIG 2020-08 */


textarea {
	resize: none;
}

.loginLinks .flag {
	margin-right: 10px;
	padding-bottom: 5px;
	position: relative;
	bottom: 1px;
}

.loginLinks .flag:hover {
	text-decoration:none;
}



@media only screen and (max-width: 1200px) {

	.field input, .field select, .field textarea{
		position: relative;
		z-index: 22222;
		margin-bottom: 10px;
	}

	#enterCompany .checkboxes {
		padding-left: 0;
	}

	.max-w_100 {
		width:100%!important;
		display: flex;
		flex-direction: column;
		margin-left: 0!important;
		padding-right: 15px!important;
	}

	.max-w_100 .radioOption {
		display: flex;
		justify-content: center;
	}

	.max-w_100 .radioOption input {
		margin-right: 10px;
	}

	.navbar-brand > img {
		width: 150px;
	}

	.max-w_100 label {
		width: auto!important
	}

	.navbar-brand {
		padding-left: 0;
	}

	.navbar {
		height: 65px;
	}

	.navRight {
		margin-top: 20px;
	}

	.loginLinks {
		display: flex;
		justify-content: center

	}

	.footer .trademark {
		float: unset;
		display: inline-block;
		margin-top: 0.4em;
		margin-bottom: 1em;
	}

	.fieldset label {
		width: 100%;
	}

	#enterLocationDatePrivyToDate .datePart input, #enterLocationDatePrivyToDate .locationPart textarea, #enterLocationDatePrivyToDate .privyToDatePart input, #enterLocationDatePrivyToDate .datePart select, #enterLocationDatePrivyToDate .privyToDatePart select {
		float: left!important;
		width: 100%!important;
		margin-top: 5px
	}

	.companiesRadio .fieldset label {
		margin-top: 30px;
	}

	#enterCompany .groupName {
		margin-top: 10px;
	}

	.pageNumber {
		text-align: center;
		width: 100%;
		margin-left: 0;
	}

	#enterPersonalData .form-control {
		width: 100%;
		display: block;
		margin-bottom: 0;
	}

	#submission .field {
		margin-bottom: 0;
	}

	#enterPersonalData label {
		width: 100%;
		margin-top: 10px;
	}

}