/* viewAndComment */
.viewAndComment{
 
	float:none;
	margin: 0 auto;

	*:not(.explanation){
		text-align: left;
	} 
	
	.alert{
		margin: 1em 0  0 0;
		width:100%;
		float:none;
	}

	textarea{
		height: 80px;
		width:100%;
	}

	.statusInfo strong{
		width:110px;
	}

	[name=message]{
		width:100%;
	}

	button, h3{
		float:none;
	}

	.messageText{
		margin: 3px 0 10px 0;
		padding: 0 0 10px 0;
	}
	
	.btn-primary{
		/*width: 9em;*/
	    text-align: center;
	}
	.sendWbMessage{
		width:9em;
		margin-top: 1em; 
	}

}