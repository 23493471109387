.caseArea{
	display:flex;
	margin-top: 40px;
	@media print {
		margin-top: 0;
	}

	* {
		text-align: left;
	}


	.caseTable{

		margin-left:50px;
		width:95%;
		min-width: 700px;
		@media print {
			margin-left:0px;
			width:99%;
			min-width: auto;
			font-size: 10px;
		}
	}	

	.caseFilter{
		/*display: inline-block;*/
		width:120px;
		/*background: #ddd;*/
		.choice{
			margin:4px 0 0 0;
			label{
				font-weight: normal;
			}
		}
		.group{
			margin-top: 15px;
		}
		.filterTitle{
			margin-top: 8px;
			font-weight: bold;		
		}
		.checkAllArea{ 
			padding-left:25px;
			input:first-child{
				margin-right:7px;
			}
		}
	}	

	.actionButtons
	{
		width:215px;
	}

	.dropdown-menu.open{
		display:block;
	}
}